import React from 'react'
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { HomeHeader } from "../components/Headers/HomeHeader";
import { SEO, Layout, Footer } from "../components";
import { BlogGrid, BlogGridItem } from '../components/Blog/BlogGrid';
import PostCard from '../components/Blog/PostCard';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Pagination from '../components/Blog/Pagination';

const PageBody = styled.div`
	background-color: #ffffff;
	padding: 4rem 0;
  width: 100%;
`
type PostProps = {
  title: string,
  slug: string,
  thumbnail: {
    description: string
    gatsbyImageData: IGatsbyImageData
  }
}

type PageProps = {
  pageContext: any,
  data: {
    allContentfulBlogPost: {
      nodes: PostProps[]
    },
  };
}

const BlogIndex = ({ data, pageContext }: PageProps) => {
  const { allContentfulBlogPost } = data

  return (
    <Layout
      header={<HomeHeader style={{ marginBottom: 0 }} />}
      footer={<Footer />}
    >
      <SEO
        title="Blog"
        desc="Find Industry Related News"
      />
      <PageBody>

        <div className="container">
          <h1>Blog</h1>
          <BlogGrid gridGap="4rem" minCol="280px">
            {allContentfulBlogPost.nodes.map(({ title, slug, thumbnail }: any) => {
              const blogTitle = title || slug;
              return (
                <BlogGridItem key={slug}>
                  <PostCard title={blogTitle} thumbnail={thumbnail} slug={slug} />
                </BlogGridItem>
              );
            })}
          </BlogGrid>
          <Pagination context={pageContext} />
        </div>
      </PageBody>
    </Layout >
  )
}

export const blogQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: createdAt }
      skip: $skip 
      limit: $limit
    ) {
      nodes {
        title
        slug
        thumbnail {
          description
          gatsbyImageData
        }
      }
    }
  }
`;


export default BlogIndex

