import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 2.5rem 0 0 0;
  display: inline-block;
  width: 100%;
  position: relative;
`;

const PageNumber = styled(Link)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  line-height: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.5rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
  cursor: pointer;

  :hover {
    color: #bda06d;
  }
  :nth-child(3) {
    margin-left: 0.5rem;
    margin-right: 0;
  }
`;

const Controls = styled.div`
  min-width: 15rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;

const Button = styled(Link)`
  padding: 1rem;
  border-radius: 2px;
  margin: 0 0 0 0.5rem;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s all;
  display: flex;
  position: absolute;
  right: 0;

  &:hover {
    color: #bda06d;

    svg path {
      fill: #bda06d;
    }
  }

  :first-child {
    right: unset;
    left: 0;
    margin: 0 0.5rem 0 0;

    svg {
      transform: rotate(180deg);
      margin: 0 0.5rem 0 0;
    }
  }
  svg {
    margin: 0 0 0 0.5rem;
  }
`;

type PaginationProps = {
  context: {
    previousPagePath: string,
    nextPagePath: string,
    paginationPath: string,
    numberOfPages: number,
    humanPageNumber: number,
  }
}

const Pagination = ({ context }: PaginationProps) => {
  const {
    previousPagePath,
    numberOfPages,
    humanPageNumber,
    paginationPath,
    nextPagePath,
  } = context;

  return (
    <>
      {numberOfPages > 1 && (
        <Wrapper>
          <Controls>
            {/* first */}
            {previousPagePath && (
              <Button to={`${previousPagePath}`}>Prev</Button>
            )}
            {previousPagePath && (
              <PageNumber to={previousPagePath}>
                {humanPageNumber - 1}
              </PageNumber>
            )}
            {/* second */}
            <PageNumber
              to={
                humanPageNumber !== 1
                  ? `${paginationPath}/${humanPageNumber}`
                  : paginationPath
              }
              style={{
                color: "#BDA06D",
                border: "1.5px solid #BDA06D",
              }}
            >
              {humanPageNumber}
            </PageNumber>
            {/* third */}
            {humanPageNumber !== numberOfPages && (
              <PageNumber to={nextPagePath}>{humanPageNumber + 1}</PageNumber>
            )}
            {humanPageNumber + 1 >= numberOfPages ? (
              ""
            ) : (
              <>
                ...
                {/* last */}
                <PageNumber to={`${paginationPath}/${numberOfPages}`}>
                  {numberOfPages}
                </PageNumber>
              </>
            )}
            {nextPagePath && <Button to={`${nextPagePath}`}>Next</Button>}
          </Controls>
        </Wrapper>
      )}
    </>
  );
};

export default Pagination;
