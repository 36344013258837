import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import styled from 'styled-components';

const Thumbnail = styled(GatsbyImage)`
  border-radius: 6px;
  margin-bottom: 0.875rem;
  height: 15.3125rem;

  @media (min-width: 555px) {
    margin-bottom: 1.125rem;
  }
  @media (min-width: 990px) {
    margin-bottom: 1.25rem;
  }
`;

const Wrapper = styled.article`
  color: inherit;
  height: 100%;
  position: relative;
  padding: 0;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  &:hover&::after {
    content: '';
    width: calc(100% + (1.125rem * 2));
    left: -1.125rem;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  a {
    display: block;
    z-index: 2;
    position: relative;
  }

  h3 {
    margin-bottom: 0;
  }

  @media (min-width: 555px) {
    padding: 1.375rem 0;
  }
`;

function PostCard({ title, thumbnail, slug }: any) {
  const image = getImage(thumbnail.gatsbyImageData);

  return (
    <Wrapper>
      <Link to={`/blog/${slug}/`}>
        <header>
          <Thumbnail image={image} alt={thumbnail.description} />
          <h3>{title}</h3>
        </header>
      </Link>
    </Wrapper>
  );
}

export default PostCard;
