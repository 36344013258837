import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      ${props => `minmax(${props.minCol}, ${props.maxCol})`}
    );
    grid-gap: ${props => props.gridGap};
  }
`;

export const GridItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 230px;

  @supports (display: grid) {
    margin-right: 0;
    margin-bottom: 0;
    min-width: unset;
  }
`;

export const BlogGrid = ({ children, gridGap, minCol, maxCol }: any) => (
  <GridWrapper gridGap={gridGap} minCol={minCol} maxCol={maxCol}>
    {children}
  </GridWrapper>
);

BlogGrid.propTypes = {
  gridGap: PropTypes.string,
  minCol: PropTypes.string,
  maxCol: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
};

BlogGrid.defaultProps = {
  gridGap: "0px",
  minCol: "230px",
  maxCol: "1fr",
};

export const BlogGridItem = ({ children }: any) => (
  <GridItemWrapper>{children}</GridItemWrapper>
);

BlogGridItem.propTypes = {
  children: PropTypes.element.isRequired,
};